<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p"></v-card>

    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="query"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :loading="isLoading"
        :replace="[
          'status',
          'created_at|formatTime',
          'to_unfreeze_at|formatTime',
          'unfroze_at',
          'unfrozen_note'
        ]"
        @get-list="fetchData"
      >
        <template #item.status="{ item }">
          <div>{{ getStatusName(item.status) }}</div>
        </template>
        <template #item.unfroze_at="{ item }">
          <div v-if="item.unfroze_at">
            {{ item.unfroze_at | formatTime }}
          </div>
          <div v-else class="error--text">未解凍</div>
        </template>
        <template #item.unfrozen_note="{ item }">
          <div class="note-wrap">{{ item.unfrozen_note }}</div>
        </template>
      </DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getFrozenOrderList } from '@/api/merchantCenter/capitalLogs';

const statusMaps = [
  { text: '凍結中', value: 'frozen' },
  { text: '已解凍', value: 'unfrozen' }
];

export default {
  async created() {
    this.fetchData();
  },

  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '凍結單號',
          value: 'order_no',
          align: 'center'
        },
        {
          text: '凍結金額',
          value: 'amt',
          align: 'center'
        },
        {
          text: '凍結狀態',
          value: 'status',
          align: 'center'
        },
        {
          text: '凍結備註',
          value: 'frozen_note',
          align: 'center'
        },
        {
          text: '建立時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center'
        },
        {
          text: '計畫解凍時間',
          value: 'to_unfreeze_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center'
        },
        {
          text: '實際解凍時間',
          value: 'unfroze_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center'
        },
        {
          text: '解凍備註',
          value: 'unfrozen_note',
          align: 'center'
        }
      ],
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'query.created_at',
          range: true,
          withTime: true
        },
        {
          label: '凍結狀態',
          type: 'select',
          name: 'query.status',
          items: statusMaps
        }
      ]
    };
  },

  computed: {},

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const FrozenOrderList = await getFrozenOrderList({
        ...condition,
        ...this.query
      });

      if (!FrozenOrderList.error) {
        this.result.total = FrozenOrderList.total;
        this.result.page = FrozenOrderList.page;
        this.$set(
          this.result,
          'list',
          FrozenOrderList.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    },
    getStatusName(key) {
      const status = statusMaps.find((s) => s.value === key);
      if (status) return status.text;
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
